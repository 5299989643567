.vue-slider {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.vue-slider-rail {
  background-color: rgba(65, 66, 71, 0.08);
  border-radius: 3px;
  cursor: pointer;
  height: 4px;
  width: calc(100% - 10px);
}

.vue-slider-process {
  background-color: #ffc107;
  border-radius: 3px;
}

.vue-slider-dot-tooltip {
  cursor: grab;
  display: flex;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 5px;
}

.vue-slider-dot-tooltip::before {
  color: #ffc107;
  content: '$';
  font-size: 0.6;
  margin-right: 4px;
}

.vue-slider-dot-tooltip-text .vue-slider-dot-tooltip-inner {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  text-align: center;
  white-space: nowrap;
}

.vue-slider-dot-handle {
  background-image: linear-gradient(to top, #f5f5fa, #fff);
  border-radius: 50%;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 2px 3px 0 rgba(93, 100, 148, 0.2);
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  height: 100%;
  width: 100%;
}

@media (max-width: 899px) {
  .vue-slider-dot {
    height: 24px !important;
    width: 24px !important;
  }
}