.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
    background-color: #ffc107;
}

.ais-RefinementList-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: rgba(65, 66, 71, 0.08);
    border: none;
    border-radius: 2px;
    height: 1rem;
    margin: 0;
    margin-right: 1rem;
    position: relative;
    width: 1rem;
}